import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class ArticleCategoryService {
    async getArticleCategories(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let articleCategories = await axiosService.get(CONSTANTS.APIURL + `article-categories-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return articleCategories;
    }
    async getArticleCategoryById(id) {
        let articleCategories = await axiosService.get(CONSTANTS.APIURL + `article-category?id=${id}` );
        return articleCategories;
    }
    async addArticleCategory(...articleCategory) {
        return await axiosService.post(CONSTANTS.APIURL + 'article-categories', ...articleCategory);
    }
    async updateArticleCategory(...articleCategory) {
        return await axiosService.put(CONSTANTS.APIURL +  'article-categories', ...articleCategory);
    }
    async deleteArticleCategory(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`article-categories?id=${id}`);
    }
    async getArticleCategory() {
        return await axiosService.get(CONSTANTS.APIURL +`article-categories`);
    }
}
export default new ArticleCategoryService()